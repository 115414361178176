import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Donation = (props) => {
  return (
    <div id="donation">
      <Helmet>
        <meta
          name="description"
          content="Support Somarasa in preserving and revitalizing the world's cultural heritage. Learn how you can contribute your stories, resources, artifacts, or skills to our mission of keeping ancient traditions alive."
        />
        <meta
          name="keywords"
          content="Somarasa donation, cultural preservation, heritage support, contribute stories, cultural resources, artifacts donation, non-monetary support, volunteer cultural heritage"
        />
        <meta
          property="og:description"
          content="Join Somarasa's mission to revive and preserve cultural heritage by contributing your stories, artifacts, or support. Together, we can keep traditions alive."
        />
        <meta
          property="og:image"
          content="./somarasa full logo red background.webp"
        />
        <meta property="og:url" content="https://somarasa.co.in/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Support Somarasa in preserving the world's cultural heritage by sharing your stories, resources, and artifacts. Join our mission today."
        />
        <meta
          name="twitter:image"
          content="./somarasa full logo red background.webp"
        />
      </Helmet>

      <div className="container">
        <div className="section-title">
          <h2>What You Can Offer...</h2>
          <p>
            At Somarasa, our mission to revive and reintroduce the world’s
            cultural heritage is a collective effort. We cannot do this alone—
            your support is crucial in helping us bring these timeless
            traditions back into the heart of society. There are many ways you
            can contribute to this important cause:
          </p>
          <div className="support-options">
            <div className="support-option">
              <h3>📝 Content and Stories</h3>
              <p>
                If you have knowledge, stories, or insights about your own
                cultural heritage, we welcome you to share them with us. Your
                contributions can help enrich our content, ensuring that more
                traditions are documented and shared with the world.
              </p>
            </div>

            <div className="support-option">
              <h3>📚 Resources and Materials</h3>
              <p>
                Do you have access to rare cultural resources, artifacts, or
                materials that can help us in our mission? Whether it’s ancient
                texts, traditional tools, or unique crafts, your contributions
                can help us preserve and showcase these treasures.
              </p>
            </div>

            <div className="support-option">
              <h3>🏺 Artefacts</h3>
              <p>
                If you own artifacts or cultural items that represent your
                heritage, consider donating or lending them to us. These pieces
                will be cared for and shared with a wider audience, helping to
                educate and inspire others about the beauty and significance of
                your culture.
              </p>
            </div>

            {/* <div className="support-option">
              <h3>💰 Monetary Support</h3>
              <p>
                Financial contributions are vital in allowing us to expand our
                initiatives, create more impactful content, and reach more people.
                Your donations help us fund research, preserve artifacts, and
                develop new ways to connect people with their cultural roots.
              </p>
            </div> */}

            <div className="support-option">
              <h3>🤝 Non-Monetary Support</h3>
              <p>
                Even if you can’t contribute financially, your time, skills, and
                expertise are incredibly valuable. Volunteer with us, share our
                mission with others, or offer your professional services to help
                us grow and reach our goals.
              </p>
            </div>
          </div>

          <p className="final-note">
            Together, we can make a difference. Your support, in whatever form
            it takes, helps us keep the flame of tradition alive. Let’s join
            hands in this mission to ensure that the wisdom and beauty of our
            cultural heritage are not lost, but cherished, celebrated, and
            passed onto future generations.
          </p>

          <div className="donationButton">
            <p
              style={{
                fontSize: "1.1em",
                lineHeight: "1.6em",
                marginBottom: "30px",
                textAlign: "left",
              }}
            >
              Imagine a world where ancient traditions and cultural wisdom are
              lost forever. Your support today can prevent that. By joining us,
              you’re not just <strong>offering support</strong>—you’re becoming
              a guardian of heritage, a preserver of the past, and a catalyst
              for a richer, more diverse future. Don’t wait—make your impact
              now.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                to="/supportPage#support"
                className="btn btn-custom btn-lg page-scroll text-center"
              >
                Support Now
              </Link>{" "}
            </div>
          </div>

          <div className="contact-info text-center">
            <h4>How You Can Help ?</h4>
            <p>
              <a href="mailto:contact@somarasa.com">Contact us via Email</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
