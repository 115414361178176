// import React, { useState, useEffect } from "react";
// import axios from "axios";

// export const Gallery = () => {
//   const [galleryItems, setGalleryItems] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         // Adjust the URL to fit your Strapi API's URL structure
//         const response = await axios.get('http://localhost:1337/api/bhagwati-galleries?populate=*');
//         console.log(response);
      
//         if (response.data && response.data.data) {
//           // Map through the items and retrieve the nested attributes
//           setGalleryItems(response.data.data.map(item => item.attributes));
//         }
//       } catch (error) {
//         console.error('Error fetching data: ', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div id="gallery" className="gallery-container">
//       <div style={{ paddingTop: "5%", minHeight: "100vh" }} className="text-center section-title">
//         <h2>Exclusive Insights</h2>
//         <h3>Gallery</h3>
      
//       <div className="row">
//         {galleryItems.map((item, index) => (
//           <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
//             <div className="gallery-card">
//               {/* Ensure that the image path is correctly formed based on your API's response */}
//               <img
//                 src={`http://localhost:1337${item.image.data.attributes.url}`} 
//                 alt={item.sourceName}
//                 className="gallery-image"
//               />
//               <div className="gallery-info">
//                 {/* Removed the redundant 'attributes' from sourceName and sourceLink as we have already mapped it out */}
//                 <p>{item.sourceName}</p>
//                 <a
//                   href={item.sourceLink}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="gallerySource"
//                 >
//                   Source
//                 </a>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//       </div>
//     </div>
//   );
// };

// export default Gallery;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const Gallery = () => {
  const [galleryItems, setGalleryItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:1337/api/bhagwati-galleries');
        console.log("Response data:", response.data);  // This will show you the structure of the response

        if (response.data && response.data.data) {
          const galleryData = response.data.data.map(item => {
            // Check if image data is available and construct the image URL
            const imageUrl = item.attributes.image?.data?.attributes.url
              ? `http://localhost:1337${item.attributes.image.data.attributes.url}`
              : '/default-image.jpg'; // Provide a default image path if needed
            return {
              ...item.attributes,
              imageUrl: imageUrl
            };
          });
          setGalleryItems(galleryData);
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);


  return (
    <div id="gallery" className="gallery-container">
      <div style={{ paddingTop: "5%", minHeight: "100vh" }} className="text-center section-title">
        <h2>Exclusive Insights</h2>
        <h3>Gallery</h3>
      
        <div className="row">
        {galleryItems.map((item, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
            <div className="gallery-card">
              <img
                src={item.imageUrl}
                alt={item.sourceName}
                className="gallery-image"
              />
              <div className="gallery-info">
                <p>{item.sourceName}</p>
                <a href={item.sourceLink} target="_blank" rel="noopener noreferrer" className="gallerySource">Source</a>
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default Gallery;
