// // import React, { useEffect, useState } from "react";
// // import axios from "axios";
// // import { useParams } from "react-router-dom";
// // import { Helmet } from "react-helmet";
// // import loadingLogo from "./somarasa logo png.png"; // Update the path as needed

// // export const BlogDetail = () => {
// //   const { id } = useParams();
// //   const [blog, setBlog] = useState(null);

// //   useEffect(() => {
// //     const fetchBlog = async () => {
// //       try {
// //         const response = await axios.get(
// //           `https://somarasa-back.onrender.com/api/blogs/${id}?populate[image]=*`
// //         );
// //         setBlog(response.data.data);
// //       } catch (error) {
// //         console.error("Failed to fetch blog:", error);
// //       }
// //     };
// //     fetchBlog();

// //     // Scroll to top when the component mounts
// //     window.scrollTo(0, 0);
// //   }, [id]);

// //   const renderContent = (content) => {
// //     if (!Array.isArray(content)) {
// //       return "No content available";
// //     }

// //     return content.map((block, index) => {
// //       if (block.type === "paragraph") {
// //         return (
// //           <p key={index}>
// //             {block.children.map((child) => child.text).join(" ")}
// //           </p>
// //         );
// //       }
// //       // Add more cases for other block types as needed
// //       return null;
// //     });
// //   };

// //   return (
// //     <div id="blog-detail">
// //       <Helmet>
// //         <meta charSet="utf-8" />
// //         <title>{blog ? blog.attributes.title : "Loading..."}</title>
// //         <meta
// //           name="description"
// //           content={blog ? blog.attributes.title : "Loading..."}
// //         />
// //       </Helmet>
// //       <p style={{ color: "transparent", fontSize: "30px" }}>Blog</p>
// //       {blog ? (
// //         <div className="container section-title">
// //           {blog.attributes.image?.data?.attributes?.url && (
// //             <img
// //               src={blog.attributes.image.data.attributes.url}
// //               alt={blog.attributes.title}
// //               className="banner-image"
// //             />
// //           )}
// //           <h2 className="text-center blog-title">{blog.attributes.title}</h2>
// //           <div className="blog-meta text-center">
// //             <span className="blog-date">
// //               {new Date(blog.attributes.date).toLocaleDateString()}
// //             </span>
// //             <span className="blog-source">{blog.attributes.sourceName}</span>
// //           </div>
// //           <div className="blog-content">
// //             <h3>Overview</h3>
// //             <div>{renderContent(blog.attributes.content)}</div>
// //           </div>
// //         </div>
// //       ) : (
// //         <div className="loading-container">
// //           <img
// //             src={loadingLogo}
// //             alt="Logo"
// //             className="loading-logo"
// //           />
// //           <p>Loading...</p>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };



// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import loadingLogo from "./somarasa logo png.webp"; // Ensure the path is correct

// export const BlogDetail = () => {
//   const { id } = useParams();
//   const [blog, setBlog] = useState(null);
//   const [likes, setLikes] = useState(0);
//   const [comments, setComments] = useState([]);
//   const [newComment, setNewComment] = useState("");
//   const [opacity, setOpacity] = useState(1);

//   const handleScroll = () => {
//     const scrollY = window.scrollY;
//     const newOpacity = Math.max(1 - scrollY / 500, 0);
//     setOpacity(newOpacity);
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     window.addEventListener("scroll", handleScroll);
//     fetchBlogDetails();
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [id]);

//   const fetchBlogDetails = async () => {
//     try {
//       const response = await axios.get(`https://somarasa-back.onrender.com/api/blogs/${id}?populate=*`);
//       setBlog(response.data.data);
//       setLikes(response.data.data.attributes.likes); // Assuming likes are stored here
//       const commentsResponse = await axios.get(`https://somarasa-back.onrender.com/api/comments?filters[blog][id][$eq]=${id}&populate=*`);
//       setComments(commentsResponse.data.data);
//     } catch (error) {
//       console.error("Failed to fetch blog details:", error);
//     }
//   };

//   // const handleLike = async () => {
//   //   try {
//   //     await axios.put(`https://somarasa-back.onrender.com/api/blogs/${id}`, {
//   //       data: {
//   //         likes: likes + 1
//   //       }
//   //     });
//   //     setLikes(likes + 1);
//   //   } catch (error) {
//   //     console.error("Failed to update likes:", error);
//   //   }
//   // };

//   // const handleCommentSubmit = async (event) => {
//   //   event.preventDefault();
//   //   try {
//   //     const response = await axios.post(`https://somarasa-back.onrender.com/api/comments`, {
//   //       data: {
//   //         text: newComment,
//   //         blog: id
//   //       }
//   //     });
//   //     setComments([...comments, response.data.data]);
//   //     setNewComment(""); // Reset the input after submission
//   //   } catch (error) {
//   //     console.error("Failed to post comment:", error);
//   //   }
//   // };

//   // const renderComments = () => {
//   //   return comments.map((comment, index) => (
//   //     <div key={index} className="comment">
//   //       <p>{comment.attributes.text}</p>
//   //     </div>
//   //   ));
//   // };

//   const renderContent = (blocks) => {
//     if (!blocks) return <p>No content available</p>;

//     return blocks.map((block, index) => {
//       switch (block.type) {
//         case "heading":
//           return <h3 key={index}>{block.children.map(child => child.text).join(' ')}</h3>;
//         case "paragraph":
//           return <p key={index}>{block.children.map(child => child.text).join(' ')}</p>;
//         default:
//           return <p key={index}>Unsupported content type</p>;
//       }
//     });
//   };

//   return (
//     <div id="blog-detail">
//       <Helmet>
//         <meta charSet="utf-8" />
//         <title>{blog ? blog.attributes.title : "Loading..."}</title>
//         <meta name="description" content={blog ? blog.attributes.title : "Loading..."} />
//       </Helmet>
//       <p style={{ color: "transparent", fontSize: "30px" }}>Blog</p>
//       {blog ? (
//         <div className="container section-title">
//           {blog.attributes.image?.data?.attributes?.url && (
//             <img
//               src={blog.attributes.image.data.attributes.url}
//               alt={blog.attributes.title}
//               className="banner-image"
//             />
//           )}
//           <h2 className="blog-title">{blog.attributes.title}</h2>
//           <div className="blog-meta">
//             <span className="blog-date">
//               {new Date(blog.attributes.date).toLocaleDateString()}
//             </span>
//             <span className="blog-source">{blog.attributes.sourceName}</span>
//           </div>
//           <div className="blog-content">
//             {blog.attributes.content ? renderContent(blog.attributes.content) : "No content available"}
//           </div>
//           {/* <button onClick={handleLike}>Like this post ({likes})</button>
//           <h3>Comments</h3>
//           {renderComments()}  
//           <form onSubmit={handleCommentSubmit}>
//             <textarea value={newComment} onChange={(e) => setNewComment(e.target.value)} />
//             <button type="submit">Post Comment</button>
//           </form> */}
//         </div>
//       ) : (
//         <div className="loading-container">
//           <img src={loadingLogo} alt="Logo" className="loading-logo" />
//           <p>Loading...</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default BlogDetail;





import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import loadingLogo from "./somarasa logo png.webp"; // Ensure the path is correct

export const BlogDetail = () => {
  const { slug } = useParams(); // Destructure slug from URL
  const [blog, setBlog] = useState(null);
  const [opacity, setOpacity] = useState(1);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", handleScroll);
    fetchBlogDetails();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [slug]);

  const fetchBlogDetails = async () => {
    try {
      const response = await axios.get(`https://somarasa-back.onrender.com/api/blogs?filters[slug][$eq]=${slug}&populate=*`);
      const blogData = response.data.data[0];
      if (blogData) {
        setBlog(blogData);
      } else {
        console.error("Blog not found");
      }
    } catch (error) {
      console.error("Failed to fetch blog details:", error);
    }
  };

  const renderContent = (blocks) => {
    if (!blocks) return <p>No content available</p>;

    return blocks.map((block, index) => {
      switch (block.type) {
        case "heading":
          return <h3 key={index}>{block.children.map(child => child.text).join(' ')}</h3>;
        case "paragraph":
          return <p key={index}>{block.children.map(child => child.text).join(' ')}</p>;
        default:
          return <p key={index}>Unsupported content type</p>;
      }
    });
  };

  return (
    <div id="blog-detail">
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>{blog ? blog.attributes.title : "Loading..."}</title>
        <meta name="description" content={blog ? blog.attributes.title : "Loading..."} />
      </Helmet> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{blog ? blog.attributes.title : "Loading..."}</title>
        <meta
          name="description"
          content={
            blog
              ? blog.attributes.excerpt || blog.attributes.title
              : "Read the latest blog on Somarasa, exploring various spiritual and cultural insights."
          }
        />
        <meta
  name="keywords"
  content={
    blog && blog.attributes.tags
      ? `Somarasa, ${
          blog.attributes.title
        }, ${blog.attributes.tags.join(", ")}`
      : "Somarasa blog, spiritual insights, cultural heritage"
  }
/>
        <meta
          property="og:title"
          content={blog ? blog.attributes.title : "Somarasa Blog"}
        />
        <meta
          property="og:description"
          content={
            blog
              ? blog.attributes.excerpt || blog.attributes.title
              : "Explore our latest blog on Somarasa, offering deep insights into spirituality and culture."
          }
        />
        <meta
          property="og:image"
          content={
            blog ? blog.attributes.image.data.attributes.url : loadingLogo
          }
        />
        <meta
          property="og:url"
          content={`https://somarasa.co.in/blog/${slug}`}
        />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={blog ? blog.attributes.title : "Somarasa Blog"}
        />
        <meta
          name="twitter:description"
          content={
            blog
              ? blog.attributes.excerpt || blog.attributes.title
              : "Read our latest blog on Somarasa, where we discuss spiritual and cultural topics."
          }
        />
        <meta
          name="twitter:image"
          content={
            blog ? blog.attributes.image.data.attributes.url : loadingLogo
          }
        />
      </Helmet>
      <p style={{ color: "transparent", fontSize: "30px" }}>Blog</p>
      {blog ? (
        <div className="container section-title">
          {blog.attributes.image?.data?.attributes?.url && (
            <img
              src={blog.attributes.image.data.attributes.url}
              alt={blog.attributes.title}
              className="banner-image"
            />
          )}
          <h2 className="blog-title">{blog.attributes.title}</h2>
          <div className="blog-meta">
            <span className="blog-date">
              {new Date(blog.attributes.date).toLocaleDateString()}
            </span>
            <span className="blog-source">{blog.attributes.sourceName}</span>
          </div>
          <div className="blog-content">
            {blog.attributes.content ? renderContent(blog.attributes.content) : "No content available"}
          </div>
        </div>
      ) : (
        <div className="loading-container">
          <img src={loadingLogo} alt="Logo" className="loading-logo" />
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
};

export default BlogDetail;
