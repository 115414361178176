import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div
          style={{ paddingTop: "5%", minHeight: "100vh" }}
          className=" section-title"
        >
          <h2 className="text-center">About Us</h2>
          <p>
            Welcome to Somarasa, where ancient wisdom meets modern
            life. We are a passionate team dedicated to promoting spiritual and
            cultural enrichment through our platform. At Somarasa, we believe
            that the key to a fulfilling life lies in reconnecting with our
            spiritual roots and cultural heritage. Our mission is to bridge the
            gap between ancient wisdom and contemporary living, offering
            insightful content, curated products, and meaningful experiences to
            guide individuals on their journey of self-discovery. Founded on the
            principles of authenticity, integrity, and inclusivity, Somarasa is
            committed to providing a space where people from all backgrounds can
            explore, learn, and grow together. Whether you are seeking spiritual
            guidance, cultural education, or simply a sense of connection,
            you'll find a warm and welcoming community here at Somarasa. Join us
            as we embark on a transformative adventure towards a deeper
            understanding of ourselves and the world around us. Together, let's
            embrace the beauty of tradition, celebrate the diversity of human
            experience, and awaken to the infinite possibilities that lie within
            us.</p><p> Thank you for being a part of the Somarasa journey. With
            gratitude, The Somarasa Team </p>
          
        </div>
      </div>
    </div>
  );
};
