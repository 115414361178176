import React from "react";

export const ContactUs = (props) => {
  return (
    <div id="contactUs" >
      <div  className="container">
        <div style={{paddingTop:"5%", minHeight:"100vh"}} className="section-title">
          <h2 className="text-center">Contact Us</h2>
          <p>
            You may contact us using the information below: Merchant Legal
            entity name:
          </p>
          <p>
            {" "}
            SOMARASA DISCOVERIES PRIVATE LIMITED Registered Address: WARD NO 48,
            PANCH TOLA, MUSHARI, MUZAFFARPUR, MUZAFFARPUR, BIHAR, 842002,
            AGHORIYA BAZAR, Bihar, PIN: 842002 Operational Address: WARD NO 48,
            PANCH TOLA, MUSHARI, MUZAFFARPUR, MUZAFFARPUR, BIHAR, 842002,
            AGHORIYA BAZAR, Bihar, PIN: 842002 Telephone No: 9234135711 E-Mail
            ID: contact@somarasa.co.in
          </p>
        </div>
      </div>
    </div>
  );
};
