// import React, { useEffect, useState } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { Helmet } from "react-helmet";
// import { Contact } from "./contact";
// import loadingLogo from "./somarasa logo png.webp"; // Update the path as needed

// export const CategoryBlogs = (props) => {
//   const { categoryId } = useParams();
//   const [blogs, setBlogs] = useState([]);
//   const [opacity, setOpacity] = useState(1);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchBlogsByCategory = async () => {
//       try {
//         const response = await axios.get(
//           `https://somarasa-back.onrender.com/api/blogs?filters[categories][id][$eq]=${categoryId}&populate=*`
//         );
//         setBlogs(response.data.data);
//         setLoading(false); // Set loading to false once data is fetched
//       } catch (error) {
//         console.error("Failed to fetch blogs:", error);
//         setLoading(false); // Set loading to false in case of an error
//       }
//     };

//     fetchBlogsByCategory();
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [categoryId]);

//   const handleScroll = () => {
//     const scrollY = window.scrollY;
//     const newOpacity = Math.max(1 - scrollY / 500, 0);
//     setOpacity(newOpacity);
//   };

//   const openBlogDetail = (id) => {
//     navigate(`/blog/${id}`);
//   };

//   // const renderContentPreview = (content) => {
//   //   const previewLength = 20;
//   //   if (!Array.isArray(content)) {
//   //     return "No content available";
//   //   }

//   //   const preview = content
//   //     .map((block) => {
//   //       if (block.type === "paragraph") {
//   //         const text = block.children.map((child) => child.text).join(" ");
//   //         return text.length > previewLength
//   //           ? text.substring(0, previewLength) + "..."
//   //           : text;
//   //       }
//   //       return null;
//   //     })
//   //     .filter(Boolean);

//   //   return preview.join(" ");
//   // };

//   const renderContentPreview = (content) => {
//     const maxWords = 20; // Target number of words
//     let wordCount = 0;
//     let finalText = "";

//     // Iterate over the content blocks until we collect enough words
//     for (const block of content) {
//         if (block.type === "paragraph") {
//             const words = block.children.map(child => child.text).join(" ").split(/\s+/);
//             for (const word of words) {
//                 if (wordCount === maxWords) break; // Stop if we reach the limit
//                 finalText += word + " ";
//                 wordCount++;
//             }
//             if (wordCount === maxWords) break; // Stop processing if we reach the limit
//         }
//     }

//     if (wordCount < maxWords) {
//         // If the total word count is less than maxWords, just use the collected text
//         return finalText.trim();
//     } else {
//         // Otherwise, add an ellipsis to indicate that the text is cut off
//         return finalText.trim() + "...";
//     }
// };

//   const headerData = props.data?.header;
//   const contactInfo = props.data?.Contact;

//   return (
//     <div id="blog">
//     <div id="category-blogs">
//       <Helmet>
//         <meta charSet="utf-8" />
//         <title>Somarasa Blog - Category</title>
//         <meta name="description" content="Discover insights and stories from our community." />
//         <link rel="canonical" href="https://somarasa.co.in/category" />
//       </Helmet>
//       <header id="header" style={{ opacity }}>
//         <div className="intro">
//           <div className="overlay">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-8 col-md-offset-2 intro-text">
//                   <h1>
//                     {headerData ? headerData.title : "Loading"}
//                     <span></span>
//                   </h1>
//                   <p style={{ marginBottom: "60px" }}>
//                     {headerData ? headerData.paragraph2 : "Loading"}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>
//       {loading ? (
//         <div className="loading-container">
//           <img src={loadingLogo} alt="Logo" className="loading-logo" />
//           <p>Loading...</p>
//         </div>
//       ) : (
//         <div className="container section-title">
//           <h2 className="text-center">Blogs</h2>
//           <div className="row">
//             {blogs.length > 0 ? (
//               blogs.map((blog, i) => (
//                 <div
//                   key={i}
//                   className="col-lg-4 col-md-6 col-sm-12 mb-4 card-full-blog"
//                   onClick={() => openBlogDetail(blog.id)}
//                 >
//                   <div className="card blog-card">
//                     {blog.attributes?.image?.data?.attributes?.url ? (
//                       <img
//                         src={blog.attributes.image.data.attributes.url}
//                         alt={blog.attributes.title}
//                         className="card-img-top blog-card-img"
//                         onError={(e) => {
//                           e.target.onerror = null;
//                           e.target.src = "default_image_path";
//                         }}
//                       />
//                     ) : (
//                       <img
//                         src="default_image_path"
//                         alt="Default Image"
//                         className="card-img-top blog-card-img"
//                       />
//                     )}
//                     <div className="card-body">
//                       <h5 className="card-title">
//                         {blog.attributes?.title || "No title available"}
//                       </h5>
//                       <div className="card-text">
//                         {renderContentPreview(blog.attributes?.content)}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <p>No blogs found for this category</p>
//             )}
//           </div>
//         </div>
//       )}
//       <Contact data={contactInfo} />
//     </div>
//     </div>
//   );
// };

// export default CategoryBlogs;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Contact } from "./contact";
import loadingLogo from "./somarasa logo png.webp"; // Ensure the path is correct

export const CategoryBlogs = (props) => {
  const { categoryName } = useParams(); // Destructure categoryName from URL
  const [blogs, setBlogs] = useState([]);
  const [categoryId, setCategoryId] = useState(null); // State for category ID
  const [categoryImage, setCategoryImage] = useState(null); // State for category image
  const [opacity, setOpacity] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoryId = async () => {
      try {
        const response = await axios.get(
          `https://somarasa-back.onrender.com/api/categories?filters[name][$eq]=${categoryName}&populate=blogs.image`
        );
        const category = response.data.data[0];
        if (category) {
          setCategoryId(category.id);
          setBlogs(category.attributes.blogs.data); // Fetch blogs from the populated response

          if (category.attributes.image && category.attributes.image.data) {
            const imageUrl = category.attributes.image.data.attributes.url;
            setCategoryImage(imageUrl); // Fetch category image from the populated response
            console.log(`Category Image URL for ${categoryName}:`, imageUrl);
          } else {
            console.warn(
              "Category image not found or improperly structured in response."
            );
          }

          console.log(`Category ID for ${categoryName}:`, category.id);
          console.log(
            `Blogs for category ${categoryName}:`,
            category.attributes.blogs.data
          );
          setLoading(false);
        } else {
          console.error("Category not found");
          setLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch category ID:", error);
        setLoading(false);
      }
    };

    fetchCategoryId();
  }, [categoryName]);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openBlogDetail = (slug) => {
    navigate(`/${categoryName}/${slug}`);
  };

  const renderContentPreview = (content) => {
    const maxWords = 20;
    let wordCount = 0;
    let finalText = "";

    for (const block of content) {
      if (block.type === "paragraph") {
        const words = block.children
          .map((child) => child.text)
          .join(" ")
          .split(/\s+/);
        for (const word of words) {
          if (wordCount === maxWords) break;
          finalText += word + " ";
          wordCount++;
        }
        if (wordCount === maxWords) break;
      }
    }

    return wordCount < maxWords ? finalText.trim() : finalText.trim() + "...";
  };

  const headerData = props.data?.header;
  const contactInfo = props.data?.Contact;

  return (
    <div id="blog">
      <div id="category-blogs">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${categoryName} - Somarasa Blog Category`}</title>
          <meta
            name="description"
            content={`Discover insightful articles and stories from the ${categoryName} category on Somarasa.`}
          />
          <meta
            name="keywords"
            content={`Somarasa, ${categoryName}, ${categoryName} blogs, cultural insights, spiritual stories, ${categoryName} articles`}
          />
          <meta
            property="og:title"
            content={`${categoryName} - Somarasa Blog Category`}
          />
          <meta
            property="og:description"
            content={`Explore the latest insights and stories from the ${categoryName} category on Somarasa.`}
          />
          <meta property="og:image" content={categoryImage || loadingLogo} />
          <meta
            property="og:url"
            content={`https://somarasa.co.in/category/${encodeURIComponent(
              categoryName
            )}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`${categoryName} - Somarasa Blog Category`}
          />
          <meta
            name="twitter:description"
            content={`Read the latest articles and stories from the ${categoryName} category on Somarasa.`}
          />
          <meta name="twitter:image" content={categoryImage || loadingLogo} />
          <link
            rel="canonical"
            href={`https://somarasa.co.in/category/${encodeURIComponent(
              categoryName
            )}`}
          />
        </Helmet>

        <header id="header" style={{ opacity }}>
          <div className="intro">
            <div className="overlay">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2 intro-text">
                    <h1>
                      {headerData ? headerData.title : "Loading"}
                      <span></span>
                    </h1>
                    <p style={{ marginBottom: "60px" }}>
                      {headerData ? headerData.paragraph2 : "Loading"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {loading ? (
          <div className="loading-container">
            <img src={loadingLogo} alt="Loading..." className="loading-logo" />
            <p>Loading...</p>
          </div>
        ) : (
          <div className="container section-title">
            <h2 className="text-center">Blogs from {categoryName}</h2>
            {categoryImage && (
              <img
                src={categoryImage}
                alt={categoryName}
                className="category-image"
                style={{ width: "100%", marginBottom: "20px" }}
              />
            )}
            <div className="row">
              {blogs.length > 0 ? (
                blogs.map((blog, i) => (
                  <div
                    key={i}
                    className="col-lg-4 col-md-6 col-sm-12 mb-4 card-full-blog"
                    onClick={() => openBlogDetail(blog.attributes.slug)}
                  >
                    <div className="card blog-card">
                      {blog.attributes?.image?.data?.attributes?.url ? (
                        <img
                          src={blog.attributes.image.data.attributes.url}
                          alt={blog.attributes.title}
                          className="card-img-top blog-card-img"
                          onError={(e) => {
                            e.target.onerror = null;
                            console.error(
                              `Failed to load image for blog ${blog.attributes.title}`
                            );
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            height: "200px",
                            backgroundColor: "#f0f0f0",
                          }}
                        />
                      )}
                      <div className="card-body">
                        <h5 className="card-title">
                          {blog.attributes?.title || "No title available"}
                        </h5>
                        <div className="card-text">
                          {renderContentPreview(blog.attributes?.content)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No blogs found for this category</p>
              )}
            </div>
          </div>
        )}
        <Contact data={contactInfo} />
      </div>
    </div>
  );
};

export default CategoryBlogs;
