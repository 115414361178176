import React, { useEffect, useState } from "react";
import { Gallery } from "./gallery";
import { Contact } from "../contact";
import { useNavigate } from "react-router-dom";

export const Bhagwati = (props) => {
  const [opacity, setOpacity] = useState(1);
  const navigate = useNavigate();

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0); 
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleExploreGallery = () => {
    navigate('/gallery'); 
  };

  const headerData = props.data?.header;
  const contactInfo = props.data?.Contact;
  const galleryData = props.data?.Gallery?.slice(0, 4);

  return (
    <div id="Bhagwati">
      <header id="header" style={{ opacity }}>
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {headerData ? headerData.title : "Loading"}
                    <span></span>
                  </h1>
                  <p style={{ marginBottom: "60px" }}>
                    {headerData ? headerData.paragraph2 : "Loading"}
                  </p>
                  <a
                    href="#insight"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Explore
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="insight" className="text-center">
        <div className="container">
          <div className="section-title">
            <Gallery data={galleryData} />
            <button onClick={handleExploreGallery} className="btn btn-custom btn-lg">
              Explore Gallery
            </button>
          </div>
        </div>
      </div>
      <div id="contact">
        <Contact data={contactInfo} />
      </div>
    </div>
  );
};
// export default Bhagwati;
