import { Image } from "./image";
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Magazine = (props) => {
  // const customStyles = `
  //     #magazine .Magazine-item {
  //       flex: 10%;
  //       box-sizing: border-box;
  //     }
  //     @media (max-width: 768px) {
  //       #magazine .Magazine-item {
  //         flex-basis: 25%;
  //       }
  //     }
  //     @media (max-width: 576px) {
  //       #magazine .Magazine-item {
  //         flex-basis: 33.33%;
  //       }
  //     }
  //     @media (max-width: 400px) {
  //       #magazine .Magazine-item {
  //         flex-basis: 100%;
  //       }
  //     }
  //     `
  return (
    <div id="magazine" className="text-center">
      <Helmet>
        <meta
          name="description"
          content="At Somarasa, we revive and celebrate cultural heritage, blending ancient traditions with the modern world. Explore how we transform the wisdom of our ancestors into a living, breathing part of today's experience."
        />
        <meta
          name="keywords"
          content="Somarasa, cultural heritage, global traditions, ancient wisdom, tradition transformation, cultural revival"
        />
        <meta
          property="og:description"
          content="Discover Somarasa, where we blend ancient traditions with modern life, preserving and celebrating cultural heritage worldwide."
        />
        <meta
          property="og:image"
          content="https://yourdomain.com/path-to-magazine-image.webp"
        />
        <meta property="og:url" content="https://somarasa.co.in/magazine" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Explore Somarasa's mission to blend ancient traditions with the modern world, reviving cultural heritage."
        />
        <meta
          name="twitter:image"
          content="https://yourdomain.com/path-to-magazine-image.webp"
        />
      </Helmet>

      {/* <style>{customStyles}</style> */}
      <div id="aboutUs" className="container">
        <div className="section-title">
          <h2>What is Somarasa? </h2>
          <div className="about-us">
            <div className="styled-paragraph ">
              <p style={{ padding: "0" }}>
                At Somarasa, we believe in the power of tradition to transform.
                We are a global movement, breathing new life into the cultural
                heritage of communities worldwide. Our mission is to take the
                timeless wisdom of our ancestors and weave it into the fabric of
                today’s world, creating a seamless blend of the old and the new.
              </p>
              <p>
                In a world that often forgets its roots, Somarasa is a guardian
                of tradition. We are here to reignite the practices, stories,
                and crafts that have been lost to time, transforming them into
                something that resonates with the present while honoring the
                past.
              </p>
              <p>
                Somarasa is not just about preserving memories; it’s about
                making them a living, breathing part of your experience.
                Together, we can ensure that the traditions that connect us all
                are not just remembered, but actively embraced and celebrated
                across the world.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="styled-paragraph">
          <p className="text-container">
            Discover the hidden treasures of lost cultures, traditions, and
            beliefs with Somarasa's exclusive E-magazines. Delve into the depths
            of ancient wisdom as we unravel the mysteries of lesser-known
            civilizations, offering insights that bridge the gap between past
            and present. Our meticulously curated E-magazines serve as windows
            into the rich tapestry of global heritage, shedding light on
            forgotten rituals, spiritual practices, and cultural nuances. Join
            us on a journey of discovery and enlightenment as we explore the
            diverse landscapes of human history and tradition.
          </p>
          </div> */}
        {/* <p style={{ textAlign: "center" }}>So, Buy Now!</p> */}

        {/* <div className="row">
            <div
            className="magazine-item"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {props.data
                ? props.data.map((d, i) => (
                    <div key={`${d.title}-${i}`} className="Magazine-item" >
                      <Image
                      
                        title={d.title}
                        largeImage={d.largeImage}
                        smallImage={d.smallImage}
                      />
                    </div>
                  ))
                : "Loading..."}

               <a href="#contact" className="btn btn-custom btn-lg page-scroll">
                  Buy Now
              </a>{" "} 
            </div>
          </div> 
          <div className="shopButton">
            <Link to="/magazine#Magazine" className="btn btn-custom btn-lg page-scroll">
              Explore More
            </Link>{" "}
          </div>*/}
      </div>
    </div>
  );
};
