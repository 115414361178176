import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

export const Header = (props) => {
  const [opacity, setOpacity] = useState(1);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header id="header" style={{ opacity }}>
      <Helmet>
        <meta
          name="description"
          content="Explore Somarasa - where tradition meets transformation. Discover the rich cultural heritage and spiritual wisdom that Somarasa, somras, and soma rasa have to offer. Dive into a journey that connects the past with the present, bringing ancient practices into the modern world."
        />
        <meta
          name="keywords"
          content="Somarasa, somras, soma rasa, somaras, cultural heritage, spiritual wisdom, ancient traditions, modern spirituality, cultural revival, Hindu culture, traditional wisdom, spiritual journey, global traditions"
        />
        <meta
          property="og:description"
          content="Join Somarasa's mission to revitalize ancient traditions and spiritual wisdom. Experience the blend of old and new with somras and soma rasa."
        />
        <meta
          property="og:image"
          content="./somarasa full logo red background.webp"
        />
        <meta property="og:url" content="https://somarasa.co.in/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Discover Somarasa, where tradition meets transformation. Explore ancient traditions, spiritual wisdom, and modern spirituality with somras and soma rasa."
        />
        <meta
          name="twitter:image"
          content="./somarasa full logo red background.webp"
        />
      </Helmet>

      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <p>{props.data ? props.data.paragraph1 : "Loading"}</p>
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p style={{ marginBottom: "60px" }}>
                  {props.data ? props.data.paragraph2 : "Loading"}
                </p>
                <a
                  href="#aboutUs"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Explore
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
