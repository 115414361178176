// import React, { useEffect, useRef } from 'react';

// const ShopifyBuyButton = ({ productId }) => {
//   const productRef = useRef(null);

//   useEffect(() => {
//     const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
//     let script = document.createElement('script');
//     script.async = true;
//     script.src = scriptURL;
//     document.head.appendChild(script);

//     script.onload = () => {
//       if (window.ShopifyBuy) {
//         if (window.ShopifyBuy.UI) {
//           ShopifyBuyInit();
//         } else {
//           loadScript();
//         }
//       } else {
//         loadScript();
//       }

//       function loadScript() {
//         script.onload = ShopifyBuyInit;
//       }

//       function ShopifyBuyInit() {
//         const client = ShopifyBuy.buildClient({
//           domain: '556480.myshopify.com',
//           storefrontAccessToken: '02042fe9087dc362686f7074d295afa9',
//         });

//         ShopifyBuy.UI.onReady(client).then((ui) => {
//           ui.createComponent('product', {
//             id: productId,
//             node: productRef.current,
//             moneyFormat: 'Rs.%20%7B%7Bamount%7D%7D',
//             options: {
//               "product": {
//                 "buttonDestination": "checkout",
//                 "contents": {
//                   "img": false,
//                   "title": false,
//                   "price": false,
//                   "options": false,
//                   "quantity": false,
//                   "button": true
//                 },
//                 "styles": {
//                   "button": {
//                     "background-color": "#000000",
//                     "color": "#ffffff",
//                     ":hover": {
//                       "background-color": "#000000",
//                       "color": "#ffffff"
//                     },
//                     "border-radius": "0px",
//                     "padding-left": "15px",
//                     "padding-right": "15px"
//                   },
//                   "quantityInput": {
//                     "display": "none"
//                   }
//                 },
//                 "text": {
//                   "button": "Add to cart"
//                 }
//               }
//             }
//           });
//         });
//       }
//     };

//     // Cleanup the script when the component unmounts
//     return () => {
//       document.head.removeChild(script);
//     };
//   }, [productId]);

//   return <div ref={productRef} id={`product-component-${productId}`}></div>;
// };

// export default ShopifyBuyButton;


import React, { useEffect, useRef } from "react";

const ShopifyBuyButton = ({ productId }) => {
  const productRef = useRef(null);

  useEffect(() => {
    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
    let script = document.createElement("script");
    script.async = true;
    script.src = scriptURL;
    document.head.appendChild(script);

    script.onload = () => {
      if (window.ShopifyBuy) {
        if (typeof window.ShopifyBuy.buildClient === 'function') {
          ShopifyBuyInit();
        } else {
          console.error('ShopifyBuy.buildClient is not a function.');
        }
      } else {
        console.error('ShopifyBuy is not available on the window object.');
      }
    };

    function ShopifyBuyInit() {
      const client = window.ShopifyBuy.buildClient({
        domain: "83bb36-43.myshopify.com",
        storefrontAccessToken: "d7eda0950fe6b24373e7e7549645ad2f",
      });

      window.ShopifyBuy.UI.onReady(client).then((ui) => {
        ui.createComponent("product", {
          id: productId,
          node: productRef.current,
          moneyFormat: "Rs.%20%7B%7Bamount%7D%7D",
          options: {
            product: {
              buttonDestination: "cart",
              contents: {
                img: false,
                title: false,
                price: false,
                options: true,
                quantity: true,
                button: true,
              },
              styles: {
                button: {
                  "background-color": "#6e0507",
                  "background-image":
                    "linear-gradient(to right, rgba(255, 4, 0, 0.8) 0%, rgba(255, 162, 0, 0.8) 100%)",
                  color: "#fff",
                  ":hover": {
                    "background-color": "#bb090c",
                    "background-image":
                    "linear-gradient(to right, #e26e01, #e26e01)",
                    },
                  ":focus": {
                    "background-color": "#bb090c",
                  },
                  "border-radius": "25px",
                  padding: "14px 34px",
                  "font-family": "Raleway, sans-serif",
                  "font-weight": "500",
                  "font-size": "15px",
                  "text-transform": "uppercase",
                },
                quantityInput: {
                  display: "none",
                },
              },
              text: {
                button: "Buy Now",
              },
            },
            cart: {
              styles: {
                button: {
                  "background-color": "#e27602",
                  ":hover": {
                    "background-color": "#bb090c",
                  },
                  ":focus": {
                    "background-color": "#bb090c",
                  },
                },
              },
              text: {
                total: "Subtotal",
                button: "Checkout",
              },
            },
            toggle: {
              styles: {
                toggle: {
                  "background-color": "#e27602",
                  ":hover": {
                    "background-color": "#bb090c",
                  },
                  ":focus": {
                    "background-color": "#bb090c",
                  },
                },
              },
            },
          },
        });
      });
    }

    // Cleanup the script when the component unmounts
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [productId]);

  return <div ref={productRef} id={`product-component-${productId}`}></div>;
};

export default ShopifyBuyButton;