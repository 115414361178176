import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { VerticalTimeline, VerticalTimelineElement } from "./timeline";
import { Contact } from "./contact";
import { useLocation } from "react-router-dom";

export const DonationPage = (props) => {
  const [opacity, setOpacity] = useState(1);
  const location = useLocation();
  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    const scrollToElement = () => {
      const hash = location.hash.replace("#", "");
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    window.scrollTo(0, 0);
    scrollToElement(); // Call the function to scroll to the element

    window.addEventListener("hashchange", scrollToElement, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("hashchange", scrollToElement);
    };
  }, [location]);

  const headerData = props.data?.header;
  const timelineData = props.data?.timeline;
  const contactInfo = props.data?.Contact;
  const waysToDonate = props.data?.waysToDonate;
  const bankDetails = props.data?.bankDetails;
  const impact = props.data?.impact;
  // const timelineData = props.data?.timelineEvents;
  // const timelineData = props.data?.DonationPage?.timelineEvents;

  return (
    <div id="donationPage">
      <Helmet>
  <meta charSet="utf-8" />
  <title>Support Us | Somarasa - Preserve Cultural Heritage & Traditions</title>
  <meta
    name="description"
    content="Support Somarasa in its mission to revive and preserve lost cultures and traditions. Your contributions help us create cultural products, provide spiritual guidance, and connect people globally with their heritage. Join us in making a difference."
  />
  <meta
    name="keywords"
    content="Somarasa support, cultural preservation, heritage revival, spiritual guidance, donation, support us, cultural products, traditional arts, global traditions, spiritual practices, community support, heritage protection, donate now"
  />
  <meta name="author" content="Somarasa Discoveries Private Limited" />
  <link rel="canonical" href="https://somarasa.co.in/SupportPage" />
  <meta property="og:title" content="Support Us | Somarasa - Preserve Cultural Heritage & Traditions" />
  <meta
    property="og:description"
    content="Join Somarasa in preserving and reviving lost cultures and traditions. Your support makes a difference in creating cultural products and providing spiritual guidance. Donate now to help us connect people with their heritage."
  />
  <meta property="og:url" content="https://somarasa.co.in/SupportPage" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="C:\Users\Lenovo\Desktop\Somarasa\src\components\somarasa full logo red background.webp" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Support Us | Somarasa - Preserve Cultural Heritage & Traditions" />
  <meta
    name="twitter:description"
    content="Help Somarasa preserve cultural heritage and traditions. Your donation supports the creation of cultural products and spiritual guidance, connecting people with their roots."
  />
  <meta name="twitter:image" content="C:\Users\Lenovo\Desktop\Somarasa\src\components\somarasa full logo red background.webp" />
</Helmet>

      <header id="header" style={{ opacity }}>
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {headerData ? headerData.title : "Loading"}
                    <span></span>
                  </h1>
                  <p style={{ marginBottom: "60px" }}>
                    {headerData ? headerData.paragraph2 : "Loading"}
                  </p>
                  <a
                    href="#support"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Explore
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="support">
        <div id="donation">
          <div className="container">
            <div className=" section-title">
              <div className="text-center"></div>
              <h2>Journey of Somarasa </h2>
              <div className="timeline-container">
                <VerticalTimeline>
                  {timelineData &&
                    timelineData.map((element, index) => (
                      <VerticalTimelineElement
                        key={index}
                        className="vertical-timeline-element--work custom-icon"
                        date={element.date}
                        // iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                        // icon={<WorkIcon />} // Customize the icon based on your needs
                      >
                        <h2 className="vertical-timeline-element-title section_title">
                          {element.title}
                        </h2>
                        {/* <h4 className="vertical-timeline-element-subtitle">
                      {element.subtitle}
                    </h4> */}
                        <p>{element.description}</p>
                      </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
              </div>
            </div>
          </div>
          <div className="container">
            <h2>Ways to Support :</h2>
            <div className="row">
              {waysToDonate &&
                waysToDonate.map((way, index) => (
                  <div key={index} className="donation-option col-md-6">
                    <h3 className="donation-option-title">{way.title}</h3>
                    <p className="donation-option-description">
                      {way.description}
                    </p>
                  </div>
                ))}
            </div>
            {/* <div>
            {bankDetails && (
              <div className="bank-details">
                <h3>Direct Bank Transfer</h3>
                <p>
                  <strong>Bank Name:</strong> {bankDetails.bankName}
                </p>
                <p>
                  <strong>Bank Account Number:</strong>{" "}
                  {bankDetails.accountNumber}
                </p>
                <p>
                  <strong>Account Name:</strong> {bankDetails.accountName}
                </p>
                <p>
                  <strong>IFSC Code:</strong> {bankDetails.ifscCode}
                </p>
              </div>
            )}
          </div> */}
          </div>
          <div id="donationImpact" className="container">
            <h2>How your support Makes a difference :</h2>
            <ul>
              {impact ? (
                impact.map((item, index) => <li key={index}>{item}</li>)
              ) : (
                <p>Loading impact data...</p>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div>
        <Contact data={contactInfo} />
      </div>
    </div>
  );
};
