import React from "react";
import { Link } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement } from "./timeline";
import { Helmet } from "react-helmet";

export const Story = (props) => {
  const timelineData = props.data?.timeline;

  return (
    <div id="story">
      <Helmet>
        <meta
          name="description"
          content="Explore the journey of Somarasa, a global movement dedicated to reviving and celebrating cultural heritage. Discover how we blend ancient traditions with modern life, creating a seamless connection between the past and the present."
        />
        <meta
          name="keywords"
          content="Somarasa, cultural heritage, global traditions, ancient wisdom, tradition transformation, cultural revival, Somarasa story"
        />
        <meta
          property="og:description"
          content="Discover the story of Somarasa, where ancient traditions meet modern life, preserving and celebrating cultural heritage worldwide."
        />
        <meta
          property="og:image"
          content="./somarasa full logo red background.webp"
        />
        <meta property="og:url" content="https://somarasa.co.in/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Explore the journey of Somarasa, blending ancient traditions with modern life to revive and celebrate cultural heritage."
        />
        <meta
          name="twitter:image"
          content="./somarasa full logo red background.webp"
        />
      </Helmet>

      <div id="support">
        <div id="donation">
          <div className="container">
            <div className=" section-title">
              <div className="text-center"></div>
              <h2>Story of Somarasa </h2>
              <div className="timeline-container">
                <VerticalTimeline>
                  {timelineData &&
                    timelineData.map((element, index) => (
                      <VerticalTimelineElement
                        key={index}
                        className="vertical-timeline-element--work custom-icon"
                        date={element.date}

                        // iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                        // icon={<WorkIcon />} // Customize the icon based on your needs
                      >
                        <div
                          className="timeline-content"
                          style={{
                            background: `linear-gradient(rgba(100, 28, 24, 0.9), rgba(93, 5, 3, 0.9)), url('/${element.backgroundImage}') center center no-repeat`,
                            backgroundSize: "cover",
                            padding: "15px",
                            borderRadius: "8px",
                          }}
                        >
                          <h2 className="vertical-timeline-element-title section_title">
                            {element.title}
                          </h2>
                          {/* <h4 className="vertical-timeline-element-subtitle">
                      {element.subtitle}
                    </h4> */}
                          <p>{element.description}</p>
                        </div>
                      </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
