// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Helmet } from "react-helmet";
// import { Contact } from "./contact";
// import Modal from "react-modal";

// // Set the app element for react-modal to avoid warnings
// Modal.setAppElement('#root');

// export const Blog = (props) => {
//   const [blogs, setBlogs] = useState([]);
//   const [selectedBlog, setSelectedBlog] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [opacity, setOpacity] = useState(1);

//   const fetchBlogs = async () => {
//     try {
//       const response = await axios.get("http://localhost:1337/api/blogs?populate[image]=*");
//       setBlogs(response.data.data);
//     } catch (error) {
//       console.error("Failed to fetch blogs:", error);
//     }
//   };

//   useEffect(() => {
//     fetchBlogs();
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const handleScroll = () => {
//     const scrollY = window.scrollY;
//     const newOpacity = Math.max(1 - scrollY / 500, 0);
//     setOpacity(newOpacity);
//   };

//   const openModal = (blog) => {
//     setSelectedBlog(blog);
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//     setSelectedBlog(null);
//   };

//   const headerData = props.data?.header;
//   const contactInfo = props.data?.Contact;

//   return (
//     <div id="blog">
//       <Helmet>
//         <meta charSet="utf-8" />
//         <title>Somarasa Blog</title>
//         <meta
//           name="description"
//           content="Discover insights and stories from our community."
//         />
//         <link rel="canonical" href="https://somarasa.co.in/blog" />
//       </Helmet>
//       <header id="header" style={{ opacity }}>
//         <div className="intro">
//           <div className="overlay">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-8 col-md-offset-2 intro-text">
//                   <h1>
//                     {headerData ? headerData.title : "Loading"}
//                     <span></span>
//                   </h1>
//                   <p style={{ marginBottom: "60px" }}>
//                     {headerData ? headerData.paragraph2 : "Loading"}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>
//       <div className="container section-title ">
//         <h2 className="text-center">Content</h2>
//         <div className="row">
//           {blogs.map((blog, i) => (
//             <div
//               key={i}
//               className="col-lg-4 col-md-6 col-sm-12 mb-4"
//               onClick={() => openModal(blog)}
//             >
//               <div className="card blog-card">
//                 {blog.attributes.image && blog.attributes.image.data && blog.attributes.image.data.attributes && blog.attributes.image.data.attributes.url ? (
//                   <img
//                     src={blog.attributes.image.data.attributes.url}
//                     alt={blog.attributes.title}
//                     className="card-img-top blog-card-img"
//                     onError={(e) => { e.target.onerror = null; e.target.src = "default_image_path"; }}
//                   />
//                 ) : (
//                   <img
//                     src="default_image_path"
//                     alt="Default Image"
//                     className="card-img-top blog-card-img"
//                   />
//                 )}
//                 <div className="card-body">
//                   <h5 className="card-title">{blog.attributes.title}</h5>
//                   <p className="card-text">
//                     {blog.attributes.content.length > 100
//                       ? blog.attributes.content.substring(0, 100) + '...'
//                       : blog.attributes.content}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         contentLabel="Blog Post"
//         className="Modal"
//         overlayClassName="Overlay"
//       >
//         {selectedBlog && (
//           <>
//             <h2>{selectedBlog.attributes.title}</h2>
//             {selectedBlog.attributes.image && selectedBlog.attributes.image.data && selectedBlog.attributes.image.data.attributes && selectedBlog.attributes.image.data.attributes.url ? (
//               <img
//                 src={selectedBlog.attributes.image.data.attributes.url}
//                 alt={selectedBlog.attributes.title}
//                 className="modal-image"
//               />
//             ) : (
//               <img
//                 src="default_image_path"
//                 alt="Default Image"
//                 className="modal-image"
//               />
//             )}
//             <p>{selectedBlog.attributes.content}</p>
//             <button onClick={closeModal}>Close</button>
//           </>
//         )}
//       </Modal>
//       <Contact data={contactInfo} />
//     </div>
//   );
// };

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Helmet } from "react-helmet";
// import { Contact } from "./contact";
// import { useNavigate } from "react-router-dom";

// export const Blog = (props) => {
//   const [blogs, setBlogs] = useState([]);
//   const [opacity, setOpacity] = useState(1);
//   const navigate = useNavigate();

//   const fetchBlogs = async () => {
//     try {
//       const response = await axios.get("http://localhost:1337/api/blogs?populate[image]=*");
//       console.log('Fetched blogs response:', response.data.data); // Log the response to inspect the structure
//       setBlogs(response.data.data);
//     } catch (error) {
//       console.error("Failed to fetch blogs:", error);
//     }
//   };

//   useEffect(() => {
//     fetchBlogs();
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const handleScroll = () => {
//     const scrollY = window.scrollY;
//     const newOpacity = Math.max(1 - scrollY / 500, 0);
//     setOpacity(newOpacity);
//   };

//   const openBlogDetail = (id) => {
//     navigate(`/blog/${id}`);
//   };

//   const renderContentPreview = (content) => {
//     const previewLength = 20; // Adjust the preview length as needed
//     if (!Array.isArray(content)) {
//       return "No content available";
//     }

//     const preview = content.map((block, index) => {
//       if (block.type === 'paragraph') {
//         const text = block.children.map(child => child.text).join(' ');
//         return text.length > previewLength ? text.substring(0, previewLength) + '...' : text;
//       }
//       return null;
//     }).filter(Boolean);

//     return preview.join(' ');
//   };

//   const headerData = props.data?.header;
//   const contactInfo = props.data?.Contact;

//   return (
//     <div id="blog">
//       <Helmet>
//         <meta charSet="utf-8" />
//         <title>Somarasa Blog</title>
//         <meta
//           name="description"
//           content="Discover insights and stories from our community."
//         />
//         <link rel="canonical" href="https://somarasa.co.in/blog" />
//       </Helmet>
//       <header id="header" style={{ opacity }}>
//         <div className="intro">
//           <div className="overlay">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-8 col-md-offset-2 intro-text">
//                   <h1>
//                     {headerData ? headerData.title : "Loading"}
//                     <span></span>
//                   </h1>
//                   <p style={{ marginBottom: "60px" }}>
//                     {headerData ? headerData.paragraph2 : "Loading"}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>
//       <div className="container section-title">
//         <h2 className="text-center">Content</h2>
//         <div className="row">
//           {blogs.map((blog, i) => (
//             <div
//               key={i}
//               className="col-lg-4 col-md-6 col-sm-12 mb-4"
//               onClick={() => openBlogDetail(blog.id)}
//             >
//               <div className="card blog-card">
//                 {blog.attributes?.image?.data?.attributes?.url ? (
//                   <img
//                     src={blog.attributes.image.data.attributes.url}
//                     alt={blog.attributes.title}
//                     className="card-img-top blog-card-img"
//                     onError={(e) => { e.target.onerror = null; e.target.src = "default_image_path"; }}
//                   />
//                 ) : (
//                   <img
//                     src="default_image_path"
//                     alt="Default Image"
//                     className="card-img-top blog-card-img"
//                   />
//                 )}
//                 <div className="card-body">
//                   <h5 className="card-title">{blog.attributes?.title || "No title available"}</h5>
//                   <div className="card-text">
//                     {renderContentPreview(blog.attributes?.content)}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//       <Contact data={contactInfo} />
//     </div>
//   );
// };

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Contact } from "./contact";
import { useNavigate } from "react-router-dom";
import loadingLogo from "./somarasa logo png.webp"; // Update the path as needed

export const Blog = (props) => {
  const [categories, setCategories] = useState([]);
  const [opacity, setOpacity] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "https://somarasa-back.onrender.com/api/categories?populate=*"
      );
      setCategories(response.data.data);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      setLoading(false); // Set loading to false in case of an error
    }
  };
  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  
  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchCategories();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openCategoryBlogs = (categoryName) => {
    navigate(`/${categoryName}`);
  };

  const headerData = props.data?.header;
  const contactInfo = props.data?.Contact;

  return (
    <div id="blog">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Somarasa Blog | Cultural Heritage & Spiritual Practices</title>
        <meta
          name="description"
          content="Explore Somarasa's blog for insights on cultural heritage, spiritual practices, and ancient traditions. Discover stories that connect you with India's rich history and spirituality."
        />
        <meta
          name="keywords"
          content="Somarasa blog, cultural heritage, spiritual practices, ancient traditions, Indian culture, spiritual insights, heritage articles, cultural stories, spiritual knowledge, global traditions"
        />
        <meta name="author" content="Somarasa Discoveries Private Limited" />
        <link rel="canonical" href="https://somarasa.co.in/blog" />
        <meta property="og:title" content="Somarasa Blog | Cultural Heritage & Spiritual Practices" />
        <meta
          property="og:description"
          content="Read insightful articles on cultural heritage, spiritual practices, and ancient traditions. Connect with India's rich history and spirituality through Somarasa's blog."
        />
        <meta property="og:url" content="https://somarasa.co.in/blog" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://somarasa.co.in/path/to/social-image.jpg" /> 
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Somarasa Blog | Cultural Heritage & Spiritual Practices" />
        <meta
          name="twitter:description"
          content="Discover insights on cultural heritage, spiritual practices, and ancient traditions in Somarasa's blog. Explore stories that connect you with India's rich history."
        />
        <meta name="twitter:image" content="https://somarasa.co.in/path/to/social-image.jpg" />
      </Helmet>
      <header id="header" style={{ opacity }}>
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {headerData ? headerData.title : "Loading"}
                    <span></span>
                  </h1>
                  <p style={{ marginBottom: "60px" }}>
                    {headerData ? headerData.paragraph2 : "Loading"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {loading ? (
        <div className="loading-container">
          <img src={loadingLogo} alt="Logo" className="loading-logo" />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="container section-title">
          <h2 className="text-center">Categories</h2>
          <div id="categories" className="row justify-content-center">
            {categories.length > 0 ? (
              categories.map((category, i) => {
                const imageUrl =
                  category.attributes?.image?.data[0]?.attributes?.url;
                return (
                  <div
                    key={i}
                    className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4 d-flex justify-content-center categories-card"
                    onClick={() => openCategoryBlogs(category.attributes.name)}
                  >
                    <div className="card category-card">
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt={category.attributes.name}
                          className="card-img-top category-card-img"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "default_image_path";
                          }}
                        />
                      ) : (
                        <img
                          src="default_image_path"
                          alt="Default Image"
                          className="card-img-top category-card-img"
                        />
                      )}
                      <div className="card-body">
                        <h5 className="card-title">
                          {category.attributes?.name || "No name available"}
                        </h5>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No categories found</p>
            )}
          </div>
        </div>
      )}
      <Contact data={contactInfo} />
    </div>
  );
};
