import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import axios from "axios";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [showSubmissionPopup, setShowSubmissionPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the form data object
    const formData = {
      data: {
        name,
        email,
        message,
      },
    };

    axios
      .post("https://somarasa-back.onrender.com/api/contacts", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Success:", response.data);
        setShowSubmissionPopup(true);
        setTimeout(() => setShowSubmissionPopup(false), 5000);
        clearState(); // Clear form after submission
        // window.location.reload();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const SubmissionPopup = ({ show, onClose }) => {
    if (!show) return null;

    return (
      <div className="submission-popup">
        <div className="popup-content">
          <p>Your Responce has been submitted. We will get back to you soon.</p>
          <h2>Submission Successful!</h2>
          <button className="btn btn-custom" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );
  };

  return (
    <div id="contact">
       <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Get in touch with Somarasa Discoveries for any inquiries or support. Fill out the contact form, and we will respond to you promptly. Discover more about our cultural and spiritual services."
          />
          <meta
            name="keywords"
            content="Somarasa contact, contact form, customer support, Somarasa Discoveries, cultural services, spiritual services, inquiries, customer inquiries, Somarasa support"
          />
          <meta name="author" content="Somarasa Discoveries Private Limited" />
          <link rel="canonical" href="https://somarasa.co.in/contact" />
          <meta
            property="og:title"
            content="Contact Us | Somarasa Discoveries"
          />
          <meta
            property="og:description"
            content="Reach out to Somarasa Discoveries for assistance with any questions or support. Fill out our contact form, and we will get back to you as soon as possible."
          />
          <meta property="og:url" content="https://somarasa.co.in/contact" />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="C:\Users\Lenovo\Desktop\Somarasa\src\components\somarasa full logo red background.webp"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Contact Us | Somarasa Discoveries"
          />
          <meta
            name="twitter:description"
            content="Need assistance or have inquiries? Contact Somarasa Discoveries through our form, and we'll respond promptly."
          />
          <meta
            name="twitter:image"
            content="C:\Users\Lenovo\Desktop\Somarasa\src\components\somarasa full logo red background.webp"
          />
        </Helmet>
      <div className="container ">
        <div className="col-md-8">
          <div className="row">
            <div style={{ paddingTop: "5%" }} className="section-title">
              <h2>Get In Touch</h2>
              <p>
                Please fill out the form below to send us an email and we will
                get back to you as soon as possible.
              </p>
            </div>

            <SubmissionPopup
              show={showSubmissionPopup}
              onClose={() => setShowSubmissionPopup(false)}
            />
            <form name="sentMessage" validate onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      required
                      value={name}
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows="4"
                  placeholder="Message"
                  required
                  value={message}
                  onChange={handleChange}
                ></textarea>
                <p className="help-block text-danger"></p>
              </div>
              <div id="success"></div>
              <button type="submit" className="btn btn-custom btn-lg">
                Send Message
              </button>
            </form>
          </div>
        </div>
        <div className="col-md-3 col-md-offset-1 contact-info">
          <div className="contact-item">
            <h3>Contact Info</h3>
            <p>
              <span>
                <i className="fa fa-map-marker"></i> Address
              </span>
              {props.data ? props.data.address : "loading"}
            </p>
          </div>
          <div className="contact-item">
            {/* <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p> */}
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-envelope-o"></i> Email
              </span>{" "}
              <a style={{ color: "#fff" }} href="mailto:contact@somarasa.com">
                contact@somarasa.com
              </a>
            </p>
          </div>
          <div className="contact-item link">
            <p>
              <Link to="/contactUs" className="link">
                Get More Information
              </Link>
            </p>
          </div>
        </div>

        <div className="col-md-12">
          <div className="policy-link row">
            <a>
              <Link to="/aboutUs">About Us</Link>
            </a>
            <a>
              <Link to="/Refund">Refund Policy</Link>
            </a>
            <a>
              <Link to="/Privacy">Privacy Policy</Link>
            </a>
            <a>
              <Link to="/Shipping">Shipping Policy</Link>
            </a>
            <a>
              <Link to="/termsAndConditions">Terms & Conditions</Link>
            </a>
          </div>
          <div className="row">
            <div className="social">
              <ul>
                <li>
                  <a
                    href={props.data ? props.data.instagram : "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href={props.data ? props.data.twitter : "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fa fa-brands fa-x-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href={props.data ? props.data.youtube : "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    href={props.data ? props.data.snapchat : "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fa fa-brands fa-snapchat"></i>
                  </a>
                </li>
                <li>
                  <a
                    href={props.data ? props.data.pinterest : "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fa fa-brands fa-pinterest"></i>{" "}
                  </a>
                </li>
                <li>
                  <a
                    href={props.data ? props.data.reddit : "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fa fa-brands fa-reddit"></i>{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Somarasa Discoveries.{" "}
            {/* <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a> */}
          </p>
        </div>
      </div>
    </div>
  );
};
