import React, { useEffect, useState } from "react";
import { Contact } from "./contact";
import { Helmet } from "react-helmet";
import ShopifyBuyButton from "./ShopifyButton";
import { useLocation } from "react-router-dom";

export const MagazinePage = (props) => {
  const [opacity, setOpacity] = useState(1);
  const location = useLocation();
  const headerData = props.data?.header;
  const contactInfo = props.data?.Contact;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentSanskritiIndex, setCurrentSanskritiIndex] = useState(0);
  const ImagesMagazine = props.data?.ImagesMagazine || [];
  const magazineSubscription = props.data?.MagazineSubscription;
  const SanskritiMagazine = props.data?.SanskritiMagazine || [];
  const SanskritiSubscription = props.data?.SanskritiSubscription;

  const calculateDiscountPercentage = (originalPrice, currentPrice) => {
    const discount = originalPrice - currentPrice;
    return Math.round((discount / originalPrice) * 100);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % ImagesMagazine.length;
        return nextIndex;
      });
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [ImagesMagazine.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSanskritiIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % SanskritiMagazine.length;
        return nextIndex;
      });
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [SanskritiMagazine.length]);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    const scrollToElement = () => {
      const hash = location.hash.replace("#", "");
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    window.scrollTo(0, 0);
    scrollToElement(); // Call the function to scroll to the element

    window.addEventListener("hashchange", scrollToElement, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("hashchange", scrollToElement);
    };
  }, [location]);

  return (
    <div id="magazinePage">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Explore Our E-Magazines | Somarasa Cultural & Spiritual Insights
        </title>
        <meta
          name="description"
          content="Discover in-depth articles on cultural heritage, ancient traditions, and spiritual practices in Somarasa's E-Magazines. Explore our Tantra and Sanskriti editions for insights into global cultures and the wisdom of the past."
        />
        <meta
          name="keywords"
          content="Somarasa E-Magazine, Tantra magazine, Sanskriti magazine, cultural heritage, spiritual practices, ancient traditions, Indian culture, global traditions, heritage preservation, spiritual insights, cultural revival, online magazines"
        />
        <meta name="author" content="Somarasa Discoveries Private Limited" />
        <link rel="canonical" href="https://somarasa.co.in/magazine" />
        <meta
          property="og:title"
          content="Explore Our E-Magazines | Somarasa Cultural & Spiritual Insights"
        />
        <meta
          property="og:description"
          content="Dive into Somarasa's E-Magazines for a deep exploration of cultural heritage and spiritual practices. Uncover ancient traditions with our Tantra and Sanskriti editions."
        />
        <meta property="og:url" content="https://somarasa.co.in/magazine" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="./somarasa full logo red background.webp"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Explore Our E-Magazines | Somarasa Cultural & Spiritual Insights"
        />
        <meta
          name="twitter:description"
          content="Explore cultural heritage and spiritual practices with Somarasa's E-Magazines. Discover the richness of ancient traditions in our Tantra and Sanskriti editions."
        />
        <meta
          name="twitter:image"
          content="./somarasa full logo red background.webp"
        />
      </Helmet>

      <header id="header" style={{ opacity }}>
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {headerData ? headerData.title : "Loading"}
                    <span></span>
                  </h1>
                  <p style={{ marginBottom: "60px" }}>
                    {headerData ? headerData.paragraph2 : "Loading"}
                  </p>
                  <a
                    href="#Magazine"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Explore
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="Magazine" className="container text-center">
        <div className="section-title somarasa">
          <div id="magazine" className="shop shop-magazine">
            <h2>Somarasa E-MAGAZINE!</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="img-container">
                  <div className="slider-container">
                    {ImagesMagazine.map((img, index) => (
                      <img
                        key={index}
                        src={img}
                        alt={`Magazine Cover ${index}`}
                        className={`styled-image ${
                          index === currentImageIndex
                            ? "center-slide"
                            : index ===
                              (currentImageIndex + 1) % ImagesMagazine.length
                            ? "right-slide"
                            : index ===
                              (currentImageIndex - 1 + ImagesMagazine.length) %
                                ImagesMagazine.length
                            ? "left-slide"
                            : "hidden-slide"
                        }`}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="text-container">
                  <div id="magazineDescription styled-paragraph">
                    <p className="styled-paragraph" style={{ paddingTop: "0" }}>
                      Introducing the{" "}
                      <span class="highlight">
                        Somarasa E-magazine Tantra Edition!
                      </span>
                    </p>
                    <p className="styled-paragraph">
                      Explore the profound world of Tantra and uncover the
                      secrets of ancient traditions. Purchase now to enhance
                      your understanding and reconnect with your rich cultural
                      heritage.
                    </p>
                    <ul>
                      <li>
                        <span class="highlight">
                          In-depth articles on Tantra practices
                        </span>
                      </li>
                      <li>Beautifully crafted poetry</li>
                      <li>Captivating visuals and sacred art</li>
                      <li>Insights into traditions and cultural beliefs</li>
                      <li>Practical guides for spiritual growth</li>
                      <li>Access to Rare Scriptures Vault</li>
                    </ul>

                    <p>
                      <span class="highlight">Purchase Today!</span> Deepen your
                      spiritual knowledge and embrace your cultural roots with
                      our Tantra Edition.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pricing-container">
            {magazineSubscription && (
              <div>
                <h4 style={{ paddingTop: "5%" }}>
                  {magazineSubscription.title}
                </h4>
                <div className="price-section">
                  <span className="current-price">
                    ₹{magazineSubscription.currentPrice}
                  </span>
                  <span className="original-price">
                    MRP ₹{magazineSubscription.originalPrice}
                  </span>
                  <span className="discount">
                    (
                    {calculateDiscountPercentage(
                      magazineSubscription.originalPrice,
                      magazineSubscription.currentPrice
                    )}
                    % OFF)
                  </span>
                </div>
                <div className="tax-info" style={{ padding: "1%" }}>
                  exclusive of all taxes
                </div>
                <div className="shopify-btn">
                  <ShopifyBuyButton productId="9641700589884" />
                </div>{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container text-center">
        <div className="section-title Sankriti">
          <div id="magazine" className="shop shop-magazine">
            <h2>Sanskriti E-MAGAZINE!</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="img-container">
                  <div className="slider-container">
                    {SanskritiMagazine.map((img, index) => (
                      <img
                        key={index}
                        src={img}
                        alt={`Magazine Cover ${index}`}
                        className={`styled-image ${
                          index === currentSanskritiIndex
                            ? "center-slide"
                            : index ===
                              (currentSanskritiIndex + 1) %
                                SanskritiMagazine.length
                            ? "right-slide"
                            : index ===
                              (currentSanskritiIndex -
                                1 +
                                SanskritiMagazine.length) %
                                SanskritiMagazine.length
                            ? "left-slide"
                            : "hidden-slide"
                        }`}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="text-container">
                  <div id="magazineDescription styled-paragraph">
                    <p className="styled-paragraph" style={{ paddingTop: "0" }}>
                      Introducing the{" "}
                      <span class="highlight">
                        Somarasa Sanskriti E-magazine!
                      </span>
                    </p>
                    <p className="styled-paragraph">
                      A new initiative to revive and celebrate lost cultures,
                      traditions, and sects. Purchase now to deepen your
                      understanding and reconnect with your cultural heritage.
                    </p>
                    <ul>
                      <li>
                        <span class="highlight">
                          In-depth articles on ancient traditions
                        </span>
                      </li>
                      <li>Captivating visuals and sacred art</li>
                      <li> Insights into forgotten cultures and sects</li>
                      <li>Practical guides for cultural preservation </li>
                    </ul>

                    <p>
                      <span class="highlight">Purchase Today!</span> Make an
                      impact by embracing and preserving our rich cultural
                      heritage with the Sanskriti E-magazine.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pricing-container">
            {SanskritiSubscription && (
              <div>
                <h4 style={{ paddingTop: "5%" }}>
                  {SanskritiSubscription.title}
                </h4>
                <div className="price-section">
                  <span className="current-price">
                    ₹{SanskritiSubscription.currentPrice}
                  </span>
                  <span className="original-price">
                    MRP ₹{SanskritiSubscription.originalPrice}
                  </span>
                  <span className="discount">
                    (
                    {calculateDiscountPercentage(
                      SanskritiSubscription.originalPrice,
                      SanskritiSubscription.currentPrice
                    )}
                    % OFF)
                  </span>
                </div>
                <div className="tax-info" style={{ padding: "1%" }}>
                  exclusive of all taxes
                </div>
                <div className="shopify-btn">
                  <ShopifyBuyButton productId="9641706586428" />
                </div>{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      <Contact data={contactInfo} />
    </div>
  );
};

export default MagazinePage;
